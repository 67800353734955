<template>
    <v-container>
  
    </v-container>
  </template>
  
  <script>
  
  import liff from "@line/liff";
  //import axios from "axios";
  
  export default {
    data () {
      return {
  
       }
    },
    created() {
      this.initializeLiff();
    },
    methods: {
      initializeLiff: function () {
  
        liff.init({
      liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
      })
      .then(() => {
          // start to use LIFF's api
          if (liff.isLoggedIn()) {
  
              liff.getProfile()
              .then(profile => {
  
                this.name =  profile.displayName 
                this.uid = profile.userId
  

  
  
              })
              .catch((err) => {
                console.log('error', err)
              })            
              
  
              //me.$router.push({ name: 'login' })
            }else{
  
              liff.login();
  
            }
      })
      .catch((err) => {
          console.log(err);
      });
  
      },
  

  
    },
  };
  </script>