<template>
  <ex_link_comp/>
</template>

<script>
import ex_link_comp from "../components/ex_link_comp";

export default {
  name: "ex_link_comp",

  components: {
    ex_link_comp,
  },
};
</script>
