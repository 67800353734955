<template>
    <div>
      <v-container grid-list-xs >
        <v-row justify="center">
            <h2 class="ma-5" >บุคลิกภาพของคุณคือ</h2>
        </v-row>
        <v-row justify="center">
            <v-img  :src="your_type.pic" contain ></v-img>
        </v-row>
        <center>                                                   
            <v-btn class="mx-1" depressed color="primary" @click="show_topic_f('1')" >ลักษณะเด่น</v-btn>
            <v-btn class="mx-1" depressed color="success" @click="show_topic_f('2')" >แรงจูงใจ</v-btn>
            <v-btn class="mx-1" depressed color="error" @click="show_topic_f('3')" >จุดที่ต้องระวัง</v-btn>
        </center>
      </v-container>

      <v-dialog v-model="dialog_a" width="300" >
        <v-card>
                <v-container>
                    <Center><h3>{{friend_name}}</h3></Center>
                    <br>
                    <v-img  :src="friend_pic.pic" contain ></v-img>
                    <br>
                    <h3>{{friend_type.intro}}</h3>
                    <br>
                    <h3 class="red--text" >{{friend_type.details}}</h3>
                </v-container>
        </v-card>
    </v-dialog>


    <v-dialog v-model="dialog_b" width="300" >
        <v-card>
                <v-container>
                    <h3>{{show_topic}}</h3>
                    <br>
                    <p>{{show_details}}</p>
                </v-container>
        </v-card>
    </v-dialog>


      <v-container>
        <center><h3>บุคลิกภาพของเพื่อนร่วมงาน</h3>
        </center>
      </v-container>   
      
      <v-container>
        <v-card
            color="#FFCDD2"
        >
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        ชื่อ
                    </th>
                    <th class="text-left">
                        ตำแหน่ง 
                    </th>
                    <th class="text-left">
                        DISC Type 
                    </th>                    
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in person"
                    :key="item.code"
                    @click="show_friend(item.type , item.name_th)"
                    >
                    <td>{{ item.name_th }}</td>
                    <td>{{ item.position }}</td>
                    <td>{{ item.type }}</td>
                    <td></td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <br>
            </v-card>
        </v-container>        
    </div>  
  </template>
  
  <script>

import axios from "axios";
  
  export default {
    name: "DISC",

    data () {
    return {
        dialog_a : false,
        dialog_b : false,
        your_type : {},
        friend_pic : '',
        friend_name : '',
        show_topic : '',
        show_details : '',
        friend_type : {},
        app_link:'',
        disc_type : [
            {
              type : 'D',
              pic : 'https://emp.ornategroup.com/images/d_type.png',
              topic1: 'ลักษณะเด่น',
              detail1: 'ลักษณะที่เห็นได้ชัดที่สุดคือ เป็นคนที่มุ่งมั่น ชัดเจน ตรงประเด็น ไม่อ้อมค้อม ความมั่นใจเต็มร้อย เป็นคนที่ตัดสินใจรวดเร็วและเด็ดขาด ซึ่งเป็นลักษณะเด่นในการเป็นผู้นำทีมได้ เป็นคนที่มักจะโฟกัสในเป้าหมาย รับผิดชอบอย่างถึงที่สุดจนกว่างานจะสำเร็จ ไม่ชอบการหนีปัญหา คนกลุ่มนี้มุ่งหน้าเท่านั้น! ถึงแม้จะเป็นงานที่ยากก็ไม่ยอมแพ้ง่าย ๆ ไม่มีลิมิต ชีวิตเกินร้อยที่แท้จริง',
              topic2: 'แรงจูงใจ',
              detail2: 'เพราะว่าเป็นคนที่มุ่งแต่เป้าหมาย ถ้ามีโอกาสใหม่ ๆ เข้ามาก็พร้อมที่จะพุ่งชน คนกลุ่มนี้ชอบที่จะพัฒนาตัวเองตลอดเวลา ถ้ามีสภาพแวดล้อมที่เหมาะกับการทำงาน ก็จะแฮปปี้สุด ๆ',
              topic3: 'จุดที่ต้องระวัง',
              detail3: 'แน่นอนว่าด้วยความที่เป็นคนมั่นใจเกินร้อย บางครั้งก็ดูเป็นคนอีโก้สูง ไม่ยอมฟังใคร ลักษณะของผู้นำที่ดี นอกจากพาทีมไปยังเป้าหมายได้สำเร็จแล้ว ก็ยังต้องฟังคำเตือน หรือข้อเสนอแนะของคนอื่นด้วย และยิ่งมีเป้าหมายที่ยิ่งใหญ่มากเท่าไร ความคาดหวังก็ยิ่งสูงตามมา ถ้าลดความคาดหวังลง และยอมรับในผลของความล้มเหลวได้ ก็จะเป็นเรื่องที่ดีขึ้นค่ะ นอกจากเป้าหมายแล้ว ระหว่างทางก็สำคัญเช่นกัน บางทีอาจจะมองข้ามรายละเอียดเล็ก ๆ น้อย ๆ ไป',
            },
            {
              type : 'I',
              pic : 'https://emp.ornategroup.com/images/i_type.png',
              topic1: 'ลักษณะเด่น',
              detail1: 'แน่นอนว่าเมื่อพูดถึงผู้มีอิทธิพลแล้ว ก็ต้องมีภาพลักษณ์ที่ดี ใคร ๆ ก็ต่างประทับใจตั้งแต่แรกเห็น คนกลุ่มนี้เป็นคนที่ค่อนข้างจะกระตือรือร้น คอยส่งพลังบวก ๆ ให้คนรอบข้างเสมอ มักจะมองโลกในแง่ดีและมีความคิดสร้างสรรค์ สามารถสร้างบรรยากาศดี ๆ ให้คนรอบข้างได้ มนุษยสัมพันธ์ดีเป็นเลิศ โน้มน้าวใจคนเก่ง เป็นตัวของตัวเองและมีความกล้าแสดงออก',
              topic2: 'แรงจูงใจ',
              detail2: 'บรรยากาศในการทำงานที่ดี ไม่ตึงเครียดจนเกินไป และการได้แสดงความสร้างสรรค์ของตัวเองออกมา จะทำให้คนกลุ่มนี้รู้สึกภูมิใจกับงานที่ทำสุด ๆ มีบรรยากาศการทำงานที่อิสระจะยิ่งชอบ',
              topic3: 'จุดที่ต้องระวัง',
              detail3: 'เป็นคนที่มักจะปฏิเสธคนไม่เก่ง และมักจะไว้ใจคนอื่นมากเกินไป ด้วยสกิลการโน้มน้าวใจคน อาจจะโดนหมั่นไส้ เพราะพูดไม่ตรงประเด็นสักที โดนรบกวนสมาธิได้ง่าย ๆ เพราะมีเรื่องให้สนใจหลายอย่าง ด้วยความอัธยาศัยดี อาจจะต้องมีขอบเขตในการช่วยเหลือบ้าง',
            },
            {
              type : 'S',
              pic : 'https://emp.ornategroup.com/images/s_type.png',
              topic1: 'ลักษณะเด่น',
              detail1: 'เป็นคนใจเย็น สุขุม รอบคอบ เป็นสายซัพที่คอยประนีประนอมกับคนรอบข้าง ค่อนข้างจะเป็นคนที่เก็บดีเทล เก็บรายละเอียดเล็ก ๆ น้อย ๆ ทำงานออกมาค่อนข้างเนี้ยบและไร้ที่ติ! พร้อมที่จะช่วยเหลือทุกคน ทุกคนมักจะชอบทำงานด้วย ชอบทำตามกฎระเบียบ เป็นผู้ฟังที่ดี และเป็นที่ปรึกษาที่ดีด้วย คนกลุ่มนี้มักจะไม่ค่อยมีปัญหาขัดแย้งกับคนอื่นเท่าไร และเหมาะที่จะเป็นคนกลางคอยไกล่เกลี่ยปัญหา',
              topic2: 'แรงจูงใจ',
              detail2: 'คนกลุ่มนี้ไม่ชอบการปะทะอารมณ์ จึงชอบงานที่มั่นคง ไม่เปลี่ยนไปเปลี่ยนมา ชอบงานที่กำหนดหน้าที่ชัดเจน ไม่ทำงานเกิน Job Description การทำงานในสถานที่ที่เป็น Work ไร้ Balance หรือสภาพเหมือนอยู่ในสนามรบตลอดเวลา อาจจะไม่ตอบโจทย์สำหรับคนกลุ่มนี้',
              topic3: 'จุดที่ต้องระวัง',
              detail3: 'คนกลุ่มนี้มักจะไม่ชอบการเปลี่ยนแปลงอะไรที่กะทันหัน อาจจะต้องทำใจบ้าง และมักโดนมองว่าเป็นคนเอื่อย ๆ ไม่พัฒนาตัวเอง ชอบยึดติดกับอะไรเดิม ๆ นอกจากนี้ การที่เป็นคนเข้าใจทุกคน ขี้เกรงใจ ไม่ชอบทะเลาะ อาจจะทำให้โดนเอาเปรียบได้ง่าย',
            },
            {
              type : 'C',
              pic : 'https://emp.ornategroup.com/images/c_type.png',
              topic1: 'ลักษณะเด่น',
              detail1: 'นักวางแผนที่ชอบทำอะไรเป็นระบบ เป็นขั้นเป็นตอน ชอบในรายละเอียด มีความรอบคอบ เป็นออกจะเป็นเพอร์เฟกชั่นนิสต์ ช่างสังเกต ชอบอะไรที่ซับซ้อน และชอบความเป๊ะ! ชอบที่จะคิดล่วงหน้า เพื่อจะประเมินความเสี่ยงที่จะเกิด วิเคราะห์ข้อมูลได้อย่างถูกต้องและแม่นยำที่สุด ชอบที่จะทำตามกฎระเบียบ และชอบการเตรียมตัวล่วงหน้า',
              topic2: 'แรงจูงใจ',
              detail2: 'ชอบการทำงานที่มีข้อมูลพร้อม และอยากโชว์ให้คนเห็นถึงศักยภาพของตัวเอง การทำงานที่ได้ใช้ความสามารถในการคิดวิเคราะห์ เป็นงานที่ค่อนข้างเหมาะ และทำให้คนกลุ่มนี้อยากทำงานไปนาน ๆ',
              topic3: 'จุดที่ต้องระวัง',
              detail3: 'บางครั้งการถามในรายละเอียดเล็ก ๆ น้อย ๆ ทำให้คนมองว่าเป็นคนจู้จี้จุกจิกเกินไป และการที่ต้องวางแผนให้เป๊ะ ก่อนที่จะทำอะไร ก็อาจจะทำให้พลาดโอกาสดี ๆ ได้เช่นกัน บางครั้งก็ควรจะลองเสี่ยงดูบ้าง จริง ๆ การขี้ระแวงก็เป็นข้อดี และก็เป็นข้อเสียได้เช่นกัน ต้องสมดุลให้ดี เป็นคนชอบวิจารณ์คนอื่น แต่ไม่ชอบให้คนอื่นวิจารณ์ตัวเอง อาจจะต้องผ่อนคลายตัวเอง แล้วยอมรับในข้อผิดพลาดบ้าง',
            },
          ],
        person : [],
        relate_type : [
            {
                code : 'DD',
                intro : 'เป็นคนชัดเจน ไม่อ้อมค้อม ความมั่นใจเต็มร้อย เป็นคนที่ตัดสินใจรวดเร็วและเด็ดขาด ไม่ชอบการหนีปัญหา',
                details : 'เรียกได้ว่าขิงก็รา ข่าก็แรง! ที่ต่างฝ่ายต่างอีโก้ใส่กัน อาจจะต้องลดระดับความแรงลงบ้าง เพื่อหลีกเลี่ยงการปะทะ',
            },
            {
                code : 'DI',
                intro : 'เป็นคนที่ค่อนข้างจะกระตือรือร้น คอยส่งพลังบวก  โลกในแง่ดีและมีความคิดสร้างสรรค์ มนุษยสัมพันธ์ดีเป็นเลิศ โน้มน้าวใจคนเก่ง',
                details : 'พยายามลดความตึงเครียดลง ด้วยบทสนทนาเล็ก ๆ น้อย ๆ อาจจะทำให้ร่วมงานกันได้ง่ายขึ้น',
            },
            {
                code : 'DS',
                intro : 'เป็นคนใจเย็น สุขุม รอบคอบ เก็บรายละเอียด เป็นสายซัพที่คอยประนีประนอมกับคนรอบข้าง ชอบทำตามกฎระเบียบ เป็นผู้ฟังที่ดี ',
                details : 'อาจจะรู้สึกว่าคนกลุ่ม S เป็นคนจุกจิก และไม่ทันใจ แต่ก็พยายามเข้าใจเขาหน่อยนะ',
            },
            {
                code : 'DC',
                intro : 'นักวางแผนที่ชอบทำอะไรเป็นระบบ เป็นขั้นเป็นตอนและคิดล่วงหน้า ชอบในรายละเอียดซับซ้อน มีความรอบคอบ ช่างสังเกต',
                details : 'ถ้าไปเล่าประสบการณ์ส่วนตัวให้คนกลุ่ม C ฟัง อาจจะทำให้เป็นดูเป็นคนขี้โม้ ถ้าจะให้เชื่อ ต้องยืนยันด้วยหลักฐาน',
            },
            {
                code : 'ID',
                intro : 'เป็นคนชัดเจน ไม่อ้อมค้อม ความมั่นใจเต็มร้อย เป็นคนที่ตัดสินใจรวดเร็วและเด็ดขาด ไม่ชอบการหนีปัญหา',
                details : 'กลุ่ม D เขาขอคนไม่เล่น! อาจจะต้องเก็บอาการขี้เล่นไว้บ้าง ต้องเก๊กขรึมและจริงจังนิดหน่อย ก่อนที่จะเข้าหา',
            },
            {
                code : 'II',
                intro : 'เป็นคนที่ค่อนข้างจะกระตือรือร้น คอยส่งพลังบวก  โลกในแง่ดีและมีความคิดสร้างสรรค์ มนุษยสัมพันธ์ดีเป็นเลิศ โน้มน้าวใจคนเก่ง',
                details : 'เป็นกลุ่มที่เข้ากันได้ ทำอะไรก็ลื่นไหลไปหมด แต่ระวังว่าติดเล่นเกินไป',
            },
            {
                code : 'IS',
                intro : 'เป็นคนใจเย็น สุขุม รอบคอบ เก็บรายละเอียด เป็นสายซัพที่คอยประนีประนอมกับคนรอบข้าง ชอบทำตามกฎระเบียบ เป็นผู้ฟังที่ดี ',
                details : 'ต้องลดความกระตือรือร้นลงนิดหน่อย หายใจเข้าลึก ๆ พูดให้ช้า ๆ ลงรายละเอียด จะช่วยให้สื่อสารกันรู้เรื่องมากขึ้น',
            },
            {
                code : 'IC',
                intro : 'นักวางแผนที่ชอบทำอะไรเป็นระบบ เป็นขั้นเป็นตอนและคิดล่วงหน้า ชอบในรายละเอียดซับซ้อน มีความรอบคอบ ช่างสังเกต',
                details : 'กลุ่ม C เป็นคนที่ชอบวางแผน บางครั้งก็อาจจะโดนแกล้งได้ อย่าเชื่อคนง่ายนะจ๊ะ',
            },
            {
                code : 'SD',
                intro : 'เป็นคนชัดเจน ไม่อ้อมค้อม ความมั่นใจเต็มร้อย เป็นคนที่ตัดสินใจรวดเร็วและเด็ดขาด ไม่ชอบการหนีปัญหา',
                details : 'ด้วยความที่ D เป็นคนใจร้อน ชอบอะไรตรงประเด็น อาจจะต้องพูดให้ชัดถ้อยชัดคำ ไม่งั้นคนกลุ่ม D จะรู้สึกหงุดหงิด',
            },
            {
                code : 'SI',
                intro : 'เป็นคนที่ค่อนข้างจะกระตือรือร้น คอยส่งพลังบวก  โลกในแง่ดีและมีความคิดสร้างสรรค์ มนุษยสัมพันธ์ดีเป็นเลิศ โน้มน้าวใจคนเก่ง',
                details : 'กลุ่ม I เป็นเพื่อนร่วมงานที่ชิล ๆ อยู่แล้ว ถ้าคุยเรื่องที่สนใจเหมือนกัน ก็ไม่มีปัญหา',
            },
            {
                code : 'SS',
                intro : 'เป็นคนใจเย็น สุขุม รอบคอบ เก็บรายละเอียด เป็นสายซัพที่คอยประนีประนอมกับคนรอบข้าง ชอบทำตามกฎระเบียบ เป็นผู้ฟังที่ดี ',
                details : 'คนขี้เกรงใจสองคนมาเจอกัน ก็อาจจะเขิน ๆ อยู่บ้าง แต่ถ้าเปิดอกคุยกันตรง ๆ แล้ว ถือว่าเป็นเรื่องที่ดีเลยแหละ',
            },
            {
                code : 'SC',
                intro : 'นักวางแผนที่ชอบทำอะไรเป็นระบบ เป็นขั้นเป็นตอนและคิดล่วงหน้า ชอบในรายละเอียดซับซ้อน มีความรอบคอบ ช่างสังเกต',
                details : 'พยายามหาเรื่องในแง่บวกมาคุยกัน จะทำให้ไม่รู้สึกเหนื่อยในการเข้าหา',
            },
            {
                code : 'CD',
                intro : 'เป็นคนชัดเจน ไม่อ้อมค้อม ความมั่นใจเต็มร้อย เป็นคนที่ตัดสินใจรวดเร็วและเด็ดขาด ไม่ชอบการหนีปัญหา',
                details : 'คนกลุ่ม D อาจจะไม่ค่อยสนใจในดีเทลจุกจิก บางครั้งการยัดแต่ข้อมูลให้ อาจจะขัดแย้งกันได้',
            },
            {
                code : 'CI',
                intro : 'เป็นคนที่ค่อนข้างจะกระตือรือร้น คอยส่งพลังบวก  โลกในแง่ดีและมีความคิดสร้างสรรค์ มนุษยสัมพันธ์ดีเป็นเลิศ โน้มน้าวใจคนเก่ง',
                details : 'คนกลุ่ม I มีแนวโน้มที่จะแสดงความคิดเห็นตามความรู้สึกสูง ถ้าหลีกเลี่ยงการปะทะอารมณ์ได้จะดีมาก เพราะจะไม่เหนื่อย',
            },
            {
                code : 'CS',
                intro : 'เป็นคนใจเย็น สุขุม รอบคอบ เก็บรายละเอียด เป็นสายซัพที่คอยประนีประนอมกับคนรอบข้าง ชอบทำตามกฎระเบียบ เป็นผู้ฟังที่ดี ',
                details : 'ต้องรู้จักประนีประนอมกับคนกลุ่ม S หน่อยนะ หาจุดตรงกลางร่วมกันให้ได้ จะทำให้งานราบรื่นขึ้น',
            },
            {
                code : 'CC',
                intro : 'นักวางแผนที่ชอบทำอะไรเป็นระบบ เป็นขั้นเป็นตอนและคิดล่วงหน้า ชอบในรายละเอียดซับซ้อน มีความรอบคอบ ช่างสังเกต',
                details : 'ไม่มีใครเข้าใจเราดีเท่ากับพวกเดียวกันแล้วแหละ แต่บางครั้งก็รู้สึกน่าเบื่อจัง พยายามหาอะไรใหม่ ๆ หรือคุยเรื่องที่สนใจร่วมกันบ้าง',
            },                                    
        ]
       }
  },


  created() {
    //let uri = window.location.search.substring(); 
    //let params = new URLSearchParams(uri);
    this.app_link = this.$store.getters.getornid
    this.check_disc( this.app_link )
    this.get_friend( this.app_link )


  },

  methods: {

    show_topic_f( topic){
        if( topic == '1'){
            this.show_details = this.your_type.detail1
            this.show_topic = this.your_type.topic1
        }
        if( topic == '2'){
            this.show_details = this.your_type.detail2
            this.show_topic = this.your_type.topic2
        }        
        if( topic == '3'){
            this.show_details = this.your_type.detail3
            this.show_topic = this.your_type.topic3
        }        
        this.dialog_b = true
    },

    show_friend( type , name_th ){

        let re_type 
        re_type =  this.your_type.type + type

        this.friend_pic = this.disc_type.find(item => item.type === type) 
        this.friend_type = this.relate_type.find(item => item.code === re_type )
        this.friend_name = name_th


        this.dialog_a = true
    
    },

    check_disc( ornid ){

        let your_disc 
        axios.get(process.env.VUE_APP_WS+"getdisc.php",{
                    params: {
                        scode: ornid
                    }
                }).then(result=>{
            your_disc  = JSON.parse(JSON.stringify(result.data))

            this.your_type = this.disc_type.find(item => item.type === your_disc)             

        })
    },

    get_friend( ornid ){

        axios.get(process.env.VUE_APP_WS+"getfriend.php",{
                    params: {
                        scode: ornid
                    }
                }).then(result=>{
                    this.person = JSON.parse(JSON.stringify(result.data))

        })

    },

  },

  };
  </script>
  
  <style>
  @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600&family=Prompt:wght@300&display=swap');

  html, body {
      font-family: 'Prompt', sans-serif;
  }

  #app {
      font-family: 'Prompt', sans-serif;
  }

  .p {
  font-size: 0.75em;
}

</style>