import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Link from "../views/ex_link.vue";
import Quation1 from "../views/quation.vue";
import Disc from "../views/Disc.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/link",
    name: "Link",
    component: Link,
  },
  {
    path: "/quation1",
    name: "Quation1",
    component: Quation1,
  },
  {
    path: "/disc",
    name: "Disc",
    component: Disc,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
