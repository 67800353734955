import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ornid : "",
  },
  mutations: {
    setornid ( state, active_ornid ){
      state.ornid = active_ornid
    },
  },
  actions: {
    ac_setornid ( context , active_ornid ){
      context.commit( 'setornid' , active_ornid )
    },
  },
  modules: {
  },
  getters:{
    getornid ( state){
      return state.ornid
    },

  }
});
