<template>
  <v-container>

  </v-container>
</template>

<script>

import liff from "@line/liff";
import axios from "axios";

export default {
  data () {
    return {

     }
  },
  created() {
    this.initializeLiff();
  },
  methods: {
    initializeLiff: function () {

      liff.init({
    liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
    })
    .then(() => {
        // start to use LIFF's api
        if (liff.isLoggedIn()) {

            liff.getProfile()
            .then(profile => {

              this.name =  profile.displayName 
              this.uid = profile.userId

              this.check_registed()


            })
            .catch((err) => {
              console.log('error', err)
            })            
            

            //me.$router.push({ name: 'login' })
          }else{

            liff.login();

          }
    })
    .catch((err) => {
        console.log(err);
    });

    },

    logout: function () {

      if (liff.isLoggedIn()) {
        liff.logout()
        this.$router.push({ name: 'home' })
      }
    },

      check_registed(){

            axios.get("https://83092122-32-20200127134849.webstarterz.com/eslipws/get_user_line_id.php",{
                        params: {
                            scode: this.uid
                        }
                    }).then(result=>{
                this.items  = JSON.parse(JSON.stringify(result.data))
                console.log( "check_registed data = " + this.items[0].emp_name  )

                if ( this.items[0].emp_name != null ){

                  this.show_otp = 0
                  this.show_emp_code = 0
                  alert(" รหัสพนักงานนี้ได้ลงทะเบียนเรียบร้อยแล้ว")

                }


            })
      },

  },
};
</script>
